<template>
	<div class="readme-article">
		<div id="教学"></div>
		<h1 id="课表-课耗">
			一、课表/课耗
		</h1>
		<h2 id="排课记录-扣费">1.排课记录-扣费</h2>
		<h3 id="排课记录">1.1排课记录</h3>
		<p>查看学员排课记录，显示学员排课信息，可进行扣费、旷课、修改、删除、加入新学员、课时补录、暂停上课等操作。</p>
		<p>
			<img src="@/assets/img/teach/1-1.png">
		</p>
		<h3 id="排课修改">1.2排课修改</h3>
		<p>修改已排课信息，可修改科目、开课时间、课时费等。</p>
		<p>
			<img src="@/assets/img/teach/1-2.png">
		</p>
		<h3 id="加入新学员">1.3加入新学员</h3>
		<p>一键加入新学员，先选中该学员已排课的课程，然后选择新学员提交，新学员将会加入到选中的课程中。</p>
		<p>
			<img src="@/assets/img/teach/1-3.png">
		</p>
		<h3 id="课时补录">1.4课时补录</h3>
		<p>管理员可对已排课的学员进行课时补录。</p>
		<p>
			<img src="@/assets/img/teach/1-4.png">
		</p>
		<h2 id="上课记录管理">2.上课记录管理</h2>
		<p>查看学员的上课记录，可通过学员姓名、上课日期等条件筛选。</p>
		<p>
			<img src="@/assets/img/teach/1-5.png">
		</p>
		<h2 id="教师已排课程">3.教师已排课程</h2>
		<p>查看教师排课记录，展示本周的教师排课内容，包括每天课时安排和科目等信息。</p>
		<p>
			<img src="@/assets/img/teach/1-6.png">
		</p>
		<h2 id="上课记录回收站">4.上课记录回收站</h2>
		<p>查看已取消的上课记录</p>
		<p>
			<img src="@/assets/img/teach/1-7.png">
		</p>
	</div>
</template>

<script>
	export default {
		name: "Staff1-1",
		data() {
			return {};
		},
		mounted() {},
		methods: {},
	};
</script>
<style scoped>
</style>
